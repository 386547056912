import React from 'react';
import Menu from '../Menu';
import { menuLinks, sections, bestPracticesDefault } from '../constants'
import ils_15 from './../images/assets/ils_15.svg';
import ServiceContent from './ServiceContent';

const title = 'Custom LLMs, Text Analysis & Sentiment Detection'
const description = 'Utilize pre-trained transformer-based AI Models and Large Language Models (LLMs) to serve your customers with personalized lingo. We can help you with sentiment analysis, classification, summarization, and more.'

const useCases = [
	'Sentiment Analysis',
	'Documents Clasification',
	'Topic Modeling',
	'Text Summarization',
	'Chat Bots',
	'Anomaly Detection'
]
const bestPractices = bestPracticesDefault.concat([
	'Use pre-trained LLMs',
	'Use of CPU or GPU for faster processing',
])

function ServicesNaturalLanguageProcessing() {
	return (
		<React.Fragment>
			< Menu />

			<ServiceContent
				pageTitle='Natural Language Processing'
				title={title}
				description={description}
				useCases={useCases}
				bestPractices={bestPractices}
				img={ils_15}
				techDescription=''
				breadCrumpMenu={sections.services.naturalLanguageProcessing.title}
				currentMenuPage={menuLinks.servicesNaturalLanguageProcessing}
			/>
		</React.Fragment>
	)
}

export default ServicesNaturalLanguageProcessing;
