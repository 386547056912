import aws from './images/tech/aws.png'
import flask from './images/tech/flask.png'
import gcp from './images/tech/gcp.png'
import python from './images/tech/python.png'
import tensorflow from './images/tech/tensorflow.png'
import pytorch from './images/tech/pytorch.png'
import jupyter from './images/tech/jupyter.png'
import redshift from './images/tech/redshift.png'
import postgresql from './images/tech/psql.png'
import s3 from './images/tech/s3.png'
import docker from './images/tech/docker.png'
import seaborn from './images/tech/seaborn.png'
// import matplotlib from './images/tech/matplotlib.png'
import pandas from './images/tech/pandas.png'
import numpy from './images/tech/numpy.png'
import scikit from './images/tech/scikit.png'
import spark from './images/tech/spark.png'
import bigquery from './images/tech/bigquery.png'
import bigtable from './images/tech/bigtable.png'
import athena from './images/tech/athena.png'
import glue from './images/tech/glue.png'
import kinesis from './images/tech/kinesis.png'
import sagemaker from './images/tech/sagemaker.png'

export const menuLinks = {
  home: '/',
  about: '/about',
  contact: '/contact',
  blog: '/blog',
  portfolio: '/portfolio',
  verticals: '/verticals',
  verticalsEcommerce: '/verticals/e-commerce',
  verticalsAdvertisement: '/verticals/advertisement',
  verticalsRealEstate: '/verticals/real-estate',
  verticalsOthers: '/verticals/others',
  services: '/services',
  servicesDataEngineering: '/services/data-engineering',
  servicesMachineLearning: '/services/machine-learning',
  servicesPredictiveAnalytics: '/services/predictive-analytics-timeseries-data',
  servicesComputerVision: '/services/computer-vision',
  servicesNaturalLanguageProcessing: '/services/natural-language-processing',
  projectETExamples: '/projects/lIlvR9jbBndHw',
  projectETOthers: '/projects/8bY7WsOUcT0ud'
}


export const sections = {
  home: {
    title: 'Home',
    path: menuLinks.home
  },
  about: {
    title: 'About',
    path: menuLinks.about
  },
  contact: {
    title: 'Contact',
    path: menuLinks.contact
  },
  blog: {
    title: 'Blog',
    path: menuLinks.blog
  },
  portfolio: {
    title: 'Portfolio',
    path: menuLinks.portfolio
  },
  services: {
    dataEngineering: {
      title: 'Data Engineering & MLOps',
      path: menuLinks.servicesDataEngineering,
      page: {
        title: 'Extract, Collect, and Transform Big Data at Scale'
      }
    },
    machineLearning: {
      title: 'Machine Learning',
      path: menuLinks.servicesMachineLearning,
      page: {
        title: 'General Machine Learning Approaches'
      }
    },
    predictiveAnalytics: {
      title: 'Predictive Analytics',
      path: menuLinks.servicesPredictiveAnalytics,
      page: {
        title: 'Current Trends and Predictive Analytics'
      }
    },
    computerVision: {
      title: 'Computer Vision (AI)',
      path: menuLinks.servicesComputerVision,
      page: {
        title: 'Patterns recognition, live feed object / actions detection, or static image analysis'
      }
    },
    naturalLanguageProcessing: {
      title: 'NLP (AI)',
      path: menuLinks.servicesNaturalLanguageProcessing,
      page: {
        title: 'Custom LLMs training, text analysis, and personalized sentiment detection'
      }
    }
  }
}

export const bestPracticesDefault = [
  'Guarantee Security & Anonnymocy across Data Lakes',
  'Batch and Stream processing of data',
  'Serverless Architecture to minimize cost and manual oversight',
  'Data Compliance and Data Privacy'
]

export const menuTitles = {
  home: 'Home'
}

// Technologies & Frameworks
export const tech = [
  ['AWS', 'https://aws.amazon.com/', aws],
  ['AWS Athena', 'https://aws.amazon.com/athena/', athena],
  ['AWS Glue', 'https://aws.amazon.com/glue/', glue],
  ['AWS Kinesis', 'https://aws.amazon.com/kinesis/', kinesis],
  ['SageMaker', 'https://aws.amazon.com/sagemaker/', sagemaker],
  ['AWS Redshift', 'https://aws.amazon.com/redshift/', redshift],
  ['PostgreSql', 'https://www.postgresql.org/', postgresql],
  ['AWS S3', 'https://aws.amazon.com/s3/', s3],
  ['Flask', 'https://flask.palletsprojects.com/en/2.2.x/', flask],
  ['GCP', 'https://cloud.google.com/', gcp],
  ['GCP BigQuery', 'https://cloud.google.com/bigquery', bigquery],
  ['GCP BigTable', 'https://cloud.google.com/bigtable', bigtable],
  ['Python', 'https://www.python.org/', python],
  ['Pandas', 'https://pandas.pydata.org/', pandas],
  ['Numpy', 'https://numpy.org/', numpy],
  ['Scikit-learn', 'https://scikit-learn.org/stable/', scikit],
  ['TensorFlow', 'https://www.tensorflow.org/', tensorflow],
  ['PyTorch', 'https://pytorch.org/', pytorch],
  ['Jupyter', 'https://jupyter.org/', jupyter],
  ['Seaborn', 'https://seaborn.pydata.org/', seaborn],
  ['Docker', 'https://www.docker.com/', docker],
  ['Apache Spark', 'https://spark.apache.org/', spark],
  // ['Kubernetes', 'https://kubernetes.io/', kubernetes],
  // ['Apache Airflow', 'https://airflow.apache.org/', airflow],
  // ['Apache Spark', 'https://spark.apache.org/', spark],
  // ['Apache Kafka', 'https://kafka.apache.org/', kafka],
  // ['Apache Hive', 'https://hive.apache.org/', hive],
  // ['Apache Hadoop', 'https://hadoop.apache.org/', hadoop],
  // ['Apache Cassandra', 'https://cassandra.apache.org/', cassandra],
  // ['Apache Flink', 'https://flink.apache.org/', flink],
  // ['Apache HBase', 'https://hbase.apache.org/', hbase],
  // ['Apache Pig', 'https://pig.apache.org/', pig],
  // ['Apache Solr', 'https://lucene.apache.org/solr/', solr],
  // ['Apache ZooKeeper', 'https://zookeeper.apache.org/', zookeeper],
  // ['Apache Zeppelin', 'https://zeppelin.apache.org/', zeppelin],
  // ['Apache Superset', 'https://superset.apache.org/', superset],
  // ['Apache NiFi', 'https://nifi.apache.org/', nifi],

]
export const techDescription = 'Cloud-based technologies can handle massive data at scale and automatically discover, report, and curate the data—all with a focus on serverless architecture requiring minimum manual oversight and reducing cost.'

export default menuLinks;
