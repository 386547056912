import React from 'react';
import { Link } from "react-router-dom";
import { menuLinks, sections } from '../constants'

const currentPageClassName = (currentPage , link) => {
  let className = ''
  if (currentPage === link) {
    className = 'current-page'
  }
  return className
} 

function ServicesSubMenu(props) {
  return (
    <React.Fragment>
      <div className="col-xl-4 col-lg-4 col-md-8 order-lg-0">
        <div className="service-sidebar md-mt-60">
          <div className="service-category mb-40">
            <ul className="style-none">
              <li className={currentPageClassName(props.currentPage, menuLinks.servicesDataEngineering)}>
                <Link to={menuLinks.servicesDataEngineering}>{sections.services.dataEngineering.title}</Link>
              </li>
              <li className={currentPageClassName(props.currentPage, menuLinks.servicesMachineLearning)}>
                <Link to={menuLinks.servicesMachineLearning}>{sections.services.machineLearning.title}</Link>
              </li>
              <li className={currentPageClassName(props.currentPage, menuLinks.servicesPredictiveAnalytics)}>
                <Link to={menuLinks.servicesPredictiveAnalytics}>{sections.services.predictiveAnalytics.title}</Link>
              </li>
              <li className={currentPageClassName(props.currentPage, menuLinks.servicesComputerVision)}>
                <Link to={menuLinks.servicesComputerVision}>{sections.services.computerVision.title}</Link>
              </li>
              <li className={currentPageClassName(props.currentPage, menuLinks.servicesNaturalLanguageProcessing)}>
                <Link to={menuLinks.servicesNaturalLanguageProcessing}>{sections.services.naturalLanguageProcessing.title}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default ServicesSubMenu;
