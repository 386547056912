import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import React, { useEffect } from 'react';
import $ from "jquery"
import AOS from "aos";
import "./vendor/aos-next/dist/aos.css";


// import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox.css";

import { menuLinks } from './constants'
import About from './About'
import Blog from './Blog'
import Home from './Home'
import Footer from './Footer'
import ServiceDataEngineering from "./pages/ServiceDataEngineering";
import ServiceMachineLearning from "./pages/ServiceMachineLearning";
import ServicePredictiveAnalytics from "./pages/ServicePredictiveAnalytics";
import ServicesComputerVision from "./pages/ServicesComputerVision";
import ServicesNaturalLanguageProcessing from "./pages/ServicesNaturalLanguageProcessing";

import ProjectETExamples from "./projects/ProjectETExamples";
import ProjectETExamplesOthers from "./projects/ProjectETExamplesOthers";

function App() {

  useEffect(() => {
    // -------------------- Site Preloader
    $('#ctn-preloader').fadeOut(); // will first fade out the loading animation
    $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
    $('body').delay(350).css({'overflow':'visible'});

    // ------------------------------- AOS Animation
    if ($("[data-aos]").length) { 
      AOS.init({
        duration: 800,
        mirror: true,
        once: true,
        offset: 50,
      });
    }
    
    // ------------------------------------- Fancybox
    //   Fancybox.fancybox({
    //     arrows: true,
    //     buttons: [
    //       "zoom",
    //       //"share",
    //       "slideShow",
    //       //"fullScreen",
    //       //"download",
    //       "thumbs",
    //       "close"
    //     ],
    //     animationEffect: "zoom-in-out",
    //     transitionEffect: "zoom-in-out",
    // });


    // ------------------------ Navigation Scroll
    $(window).on('scroll', function (){   
      var sticky = $('.sticky-menu'),
      scroll = $(window).scrollTop();
      if (scroll >= 100) sticky.addClass('fixed');
      else sticky.removeClass('fixed');

    });
    
  }, []);

  return(
    <div className="main-page-wrapper">
      <BrowserRouter>

        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        <Routes>
          <Route path={menuLinks.servicesDataEngineering} element={<ServiceDataEngineering />}/>
          <Route path={menuLinks.servicesMachineLearning} element={<ServiceMachineLearning />}/>
          <Route path={menuLinks.servicesPredictiveAnalytics} element={<ServicePredictiveAnalytics />}/>
          <Route path={menuLinks.servicesComputerVision} element={<ServicesComputerVision />}/>
          <Route path={menuLinks.servicesNaturalLanguageProcessing} element={<ServicesNaturalLanguageProcessing />}/>
          <Route path={menuLinks.blog} element={<Blog />}/>
          <Route path={menuLinks.about} element={<About />}/>
          <Route path={menuLinks.home} element={<Home />} />
          <Route path={menuLinks.projectETExamples} element={<ProjectETExamples />} />
          <Route path={menuLinks.projectETOthers} element={<ProjectETExamplesOthers />} />
          <Route path='*' element={<Home />} />
        </Routes>

        <Footer />

        <button className="scroll-top">
          <i className="bi bi-arrow-up-short"></i>
        </button>
      </BrowserRouter>
    </div>
  )
}

export default App;
