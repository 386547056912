import React from 'react';
import Menu from '../Menu';
import { menuLinks, sections, bestPracticesDefault } from '../constants'
import ils_18 from './../images/assets/ils_18.svg';

import ServiceContent from './ServiceContent';

const title = 'Patterns and Objects Recognition'
const description = 'Computer Vision has reached a critical moment in adoption and maturity in open-source tooling. There are countless state-of-the-art pre-trained models ready to use. Those Models can be re-trained for a specific use case or used as is. You can leverage Open-source computer vision AI Models for Object Detection, Tracking, Face Detection, Pose Detection, and others.'

const useCases = [
	'Track Anomaly detections with live feed videos',
	'Optimize existing video/image catalogs with video tags and better understand their context',
	'Track fitness movement and provide real-time feedback',
	'Detect actions in live videos'
]
const bestPractices = bestPracticesDefault.concat([
	'Use pre-trained models for object detection, tracking, face detection, pose detection and others',
	'Use of GPU for faster processing',
])

function ServicesComputerVision() {
	return (
		<React.Fragment>
			< Menu />

			<ServiceContent
				pageTitle='Computer Vision'
				title={title}
				description={description}
				useCases={useCases}
				bestPractices={bestPractices}
				img={ils_18}
				techDescription=''
				breadCrumpMenu={sections.services.computerVision.title}
				currentMenuPage={menuLinks.servicesComputerVision}
			/>

		</React.Fragment>
	)
}

export default ServicesComputerVision;
