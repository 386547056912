import React from 'react';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

import Menu from './Menu';
import { Link } from "react-router-dom";

import shape_38 from './images/shape/shape_38.svg';
import shape_39 from './images/shape/shape_39.svg';

import article_1 from './images/blog/lambda-best-practice.jpg';
import article_2 from './images/blog/the-most-for-your-money-from-amazon-s3.jpg';
import article_3 from './images/blog/the-most-for-your-money-from-amazon-rds.jpg';
import article_open_source_ml from './images/blog/open-source-ml-models-ai-use-cases.jpg';


function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Blog - Industry Technical Insights</title>
        <meta name="description" content='CaptainMe.AI - Data Engineering, Machine Learning and AI Lab'></meta>
        <meta property="og:url" content={window.location}></meta>
        <meta property="og:title" content='CaptainMe.AI - Blog - Industry Technical Insights'></meta>
      </Helmet>

      < Menu />

      {/**
			=============================================
				Theme Inner Banner / Header
			============================================== 
      */}
      <div className="theme-inner-banner">
        <div className="container">
          <h2 className="intro-title text-center">Industry Technical Insights</h2>
          <ul className="page-breadcrumb style-none d-flex justify-content-center">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="current-page">Blog</li>
          </ul>
        </div>
        <img src={shape_38} alt="" className="shapes shape-one" />
        <img src={shape_39} alt="" className="shapes shape-two" />
      </div>

      {/* <!--
			=====================================================
				Blog Section Three
			=====================================================
			--> */}
      <div className="blog-section-three mb-150 lg-mb-100">
        <div className="container">


          <div className="row gx-xxl-5">
            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
              <article className="blog-meta-one color-two tran3s mt-45">
                <figure className="post-img m0">
                  <a
                    href="https://medium.com/captainme-ai/open-source-ml-models-ai-use-cases-932d44f294f3"
                    className="w-100 d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={article_open_source_ml} alt="" className="w-100 tran4s" />
                  </a>
                </figure>
                <div className="post-data">
                  <div className="post-tag">
                    <a
                      href="https://medium.com/captainme-ai/open-source-ml-models-ai-use-cases-932d44f294f3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ML for Business
                    </a> - 6 minute read
                  </div>
                  <a
                    href="https://medium.com/captainme-ai/open-source-ml-models-ai-use-cases-932d44f294f3"
                    className="blog-title"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5>Open Source ML Models & AI Use Cases</h5>
                  </a>
                </div>
              </article>
            </div>

            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
              <article className="blog-meta-one color-two tran3s mt-45">
                <figure className="post-img m0">
                  <a
                    href="https://towardsaws.com/best-practices-for-aws-lambda-deployments-2d78052402c1"
                    className="w-100 d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={article_1} alt="" className="w-100 tran4s" />
                  </a>
                </figure>
                <div className="post-data">
                  <div className="post-tag">
                    <a
                      href="https://towardsaws.com/best-practices-for-aws-lambda-deployments-2d78052402c1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AWS Best Practice
                    </a> - 7 minute read
                  </div>
                  <a
                    href="https://towardsaws.com/best-practices-for-aws-lambda-deployments-2d78052402c1"
                    className="blog-title"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5>Best Practices for AWS Lambda Deployments</h5>
                  </a>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="100">
              <article className="blog-meta-one color-two tran3s mt-45">
                <figure className="post-img m0">
                  <a
                    href="https://towardsdatascience.com/the-most-for-your-money-from-amazon-s3-6536c78189da"
                    className="w-100 d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={article_2} alt="" className="w-100 tran4s" />
                  </a>
                </figure>
                <div className="post-data">
                  <div className="post-tag">
                    <a
                      href="https://towardsdatascience.com/the-most-for-your-money-from-amazon-s3-6536c78189da"
                      target="_blank"
                      rel="noreferrer"
                    >AWS Best Practice</a> - 6 minute read</div>
                  <a
                    href="https://towardsdatascience.com/the-most-for-your-money-from-amazon-s3-6536c78189da"
                    className="blog-title"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5>The Most for Your Money from Amazon S3</h5>
                  </a>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="200">
              <article className="blog-meta-one color-two tran3s mt-45">
                <figure className="post-img m0">
                  <a
                    href="https://towardsdatascience.com/the-most-for-your-money-from-amazon-rds-51784064da1f" className="w-100 d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={article_3} alt="" className="w-100 tran4s" />
                  </a>
                </figure>
                <div className="post-data">
                  <div className="post-tag">
                    <a
                      href="https://towardsdatascience.com/the-most-for-your-money-from-amazon-rds-51784064da1f"
                      target="_blank"
                      rel="noreferrer"
                    >AWS Best Practice</a> - 7 minute read</div>
                  <a
                    href="https://towardsdatascience.com/the-most-for-your-money-from-amazon-rds-51784064da1f"
                    className="blog-title"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5>The Most for Your Money from Amazon RDS</h5>
                  </a>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

export default Blog;
