import React from 'react';
import { useEffect } from 'react';

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Menu from './Menu';
import Technologies from './components/Technologies';
import { menuLinks, sections, techDescription } from './constants'
import ils_13 from './images/assets/ils_13.svg';
import ils_13_1 from './images/assets/ils_13_1.svg';
import ils_13_2 from './images/assets/ils_13_2.svg';

import shape_06 from './images/shape/shape_06.svg';
import shape_07 from './images/shape/shape_07.svg';
// import shape_35 from './images/shape/shape_35.svg';
import icon_32 from './images/icon/icon_32.svg'; // NLP
import icon_09 from './images/icon/icon_09.svg';
import icon_10 from './images/icon/icon_10.svg'; // Machine Learning
import icon_11 from './images/icon/icon_11.svg';
import icon_13 from './images/icon/icon_13.svg';
import icon_38 from './images/icon/icon_38.svg'; // Computer Vision
// import info from './images/gallery/captainme-ai-infographic.jpg'; // Infographic


// import ils_15   from './images/assets/ils_15.svg'
// import ils_15_1 from './images/assets/ils_15_1.svg'
// import ils_15_2 from './images/assets/ils_15_2.svg'
// import ils_15_3 from './images/assets/ils_15_3.svg' 
// import ils_15_4 from './images/assets/ils_15_4.svg'
// import ils_15_5 from './images/assets/ils_15_5.svg'
// import ils_15_6 from './images/assets/ils_15_6.svg'
// import ils_15_7 from './images/assets/ils_15_7.svg'

function Home() {

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<React.Fragment>
			<Helmet>
				<title>CaptainMe.AI - Data Engineering, Machine Learning and AI Lab</title>
				<meta name="description" content="CaptainMe.AI - Data Engineering, Machine Learning and AI Lab"></meta>
			</Helmet>

			< Menu />
			{/**
      =============================================
        Theme Hero Banner
      ============================================== 
      */}

			<div className="hero-banner-five">
				<div className="container">
					<div className="row">
						<div className="col-xxl-6 col-md-7">
							<h1 className="hero-heading">
								Leverage <span>your data</span>,<br />
								elevate <span>your business</span>,<br />
								be the captain of <span>your firm.</span>
							</h1>
							<p className="text-lg mb-50 pt-40 pe-xl-5 md-pt-30">
								The mission of CaptainMe AI is to grow, support and elevate business initiatives via comprehensive data-driven solutions and accessibility to Artificial intelligence technologies.
								<br /><br />
								CaptainMe AI empowers companies and teams to leverage, understand and integrate modern machine learning solutions in their products and daily practices.
							</p>
						</div>
					</div>
				</div>
				<div className="illustration-holder">
					<img src={ils_13} alt="" className="main-illustration ms-auto" />
					<img src={ils_13_1} alt="" className="shapes shape-one" />
					<img src={ils_13_2} alt="" className="shapes shape-two" data-aos="fade-down" />
					<img src={ils_13_2} alt="" className="shapes shape-three" data-aos="fade-down" />
				</div>
				<div className="shapes oval-one"></div>
			</div>

			{/* <!-- 
			=============================================
				Feature Section Four
			============================================== 
			--> */}
			<div className="fancy-feature-four position-relative lg-mt-50">
				<div className="bg-wrapper">
					<div className="inner-container">
						<div className="container">
							<div className="row gx-xxl-5">
								<div className="col-lg-4 mt-40 d-flex" data-aos="fade-up">
									<div className="d-flex justify-content-center flex-column">
										<div className="title-style-one">
											<div className="sc-title-three">Services</div>
											<h2 className="main-title">Our areas of expertise</h2>
										</div>
										<div>

										</div>
									</div>
								</div>

								{/* Data Engineering */}
								<div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="200">
									<div className="block-style-four">
										<div className="icon d-flex align-items-end justify-content-center">
											<img src={icon_11} alt="Data Engineering Services" />
										</div>
										<Link to={menuLinks.servicesDataEngineering}>
											<h5>
												{sections.services.dataEngineering.title}
											</h5>
										</Link>
										<p>{sections.services.dataEngineering.page.title}</p>
										<Link to={menuLinks.servicesDataEngineering} className='more-btn'>
											<img src={icon_13} alt="" className="tran3s" />
										</Link>
									</div>
								</div>

								{/* Machine Learning */}
								<div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="200">
									<div className="block-style-four">
										<div className="icon d-flex align-items-end justify-content-center">
											<img src={icon_10} alt={sections.services.machineLearning.title} />
										</div>
										<Link to={menuLinks.servicesMachineLearning}>
											<h5>
												{sections.services.machineLearning.title}
											</h5>
										</Link>
										<p>
											General Machine Learning approaches for CLV, Churn, Anomaly Detection, and others ...
										</p>
										<Link to={menuLinks.servicesMachineLearning} className='more-btn'>
											<img src={icon_13} alt="" className="tran3s" />
										</Link>
									</div>
								</div>

								{/* Predictive Analytics / Timeseries */}
								<div className="col-lg-4 mt-40 d-flex" data-aos="fade-up" data-aos-delay="300">
									<div className="block-style-four">
										<div className="icon d-flex align-items-end justify-content-center">
											<img src={icon_09} alt="" />

										</div>
										<Link to={menuLinks.servicesPredictiveAnalytics}>
											<h5>
												Predictive Analytics / Time Series
											</h5>
										</Link>
										<p>Understand current trends and stay ahead with predictive analytics</p>
										<Link to={menuLinks.servicesPredictiveAnalytics}>
											<img src={icon_13} alt="" className="tran3s" />
										</Link>
									</div>
								</div>

								{/* Computer Vision */}
								<div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="200">
									<div className="block-style-four">
										<div className="icon d-flex align-items-end justify-content-center">
											<img src={icon_38} alt="" />
										</div>
										<Link to={menuLinks.servicesComputerVision}>
											<h5>
												Computer Vision
											</h5>
										</Link>
										<p>{sections.services.computerVision.page.title}</p>
										<Link to={menuLinks.servicesComputerVision}>
											<img src={icon_13} alt="" className="tran3s" />
										</Link>
									</div>
								</div>

								{/* Natural Language Processing */}
								<div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="100">
									<div className="block-style-four">
										<div className="icon d-flex align-items-end justify-content-center">
											<img src={icon_32} alt="" />
										</div>
										<Link to={menuLinks.servicesNaturalLanguageProcessing}>
											<h5>
												Natural Language Processing
											</h5>
										</Link>
										<p>{sections.services.naturalLanguageProcessing.page.title}</p>
										<Link to={menuLinks.servicesNaturalLanguageProcessing}>
											<img src={icon_13} alt="" className="tran3s" />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<img src={shape_06} alt="" className="shapes shape-one" />
				<img src={shape_07} alt="" className="shapes shape-two" />
			</div>

			<div className="tech-we-use-home-wrapper position-relative">
				<div className="container">
					<div className="row">

						<div className="d-flex justify-content-center flex-column">
							<div className="title-style-one">
								{/* <div className="sc-title-three">Services</div> */}
								<h2 className="main-title">Technologies we use</h2>
							</div>
						</div>
						<p>{techDescription}</p>
						<Technologies ratio="2" />
					</div>
				</div>
			</div>


			{/* <!-- 
			=============================================
				Feature Section Nineteen
			============================================== 
			--> */}
			{/* <div className="fancy-feature-nineteen position-relative pt-130 lg-pt-80">
				<div className="container">
					<div className="row">
						<div className="col-xxl-5 col-lg-6 col-md-7">
							<div className="block-style-thirteen" data-aos="fade-right">
								<div className="title-style-three pb-15">
									<div className="sc-title">Process</div>
									<h2 className="main-title">How we work</h2>
								</div> 
								<ul className="style-none list-item">
									<li data-aos="fade-up">
										<div className="numb tran3s">1</div>
										<h6>Understand the Problem & Capture the data </h6>
										<span>Learn the business problem and acquire the data needed for a solution.</span>
									</li>
									<li data-aos="fade-up" data-aos-delay="50">
										<div className="numb tran3s">2</div>
										<h6>Analyse</h6>
										<span>Examine the data and compile possible Machine Learning & AI solutions</span>
									</li>
									<li data-aos="fade-up" data-aos-delay="100">
										<div className="numb tran3s">3</div>
										<h6>Deploy ML Model & Increase Business value</h6>
										<span>Apply the ML Solution to the business need.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="illustration-holder" data-aos="fade-left">
					<img src={ils_15} alt="" className="w-100 main-illustration"/>
					<img src={ils_15_1} alt="" className="shapes shape-one"/>
					<img src={ils_15_2} alt="" className="shapes shape-two"/>
					<img src={ils_15_3} alt="" className="shapes shape-three"/>
					<img src={ils_15_4} alt="" className="shapes shape-four"/>
					<img src={ils_15_5} alt="" className="shapes shape-five" data-aos="fade-down" data-aos-delay="200" data-aos-duration="2000"/>
					<img src={ils_15_6} alt="" className="shapes shape-six" data-aos="fade-down" data-aos-delay="100" data-aos-duration="2000"/>
					<img src={ils_15_7} alt="" className="shapes shape-seven" data-aos="fade-down" data-aos-duration="2000"/>
				</div>
				<div className="shapes o}al-one"></div>
				<div className="shapes oval-two"></div>
				<img src={shape_35} alt="" className="shapes bg-shape"/>
			</div> */}




			{/* <div className="container">
					<div className="inner-container bg-color">
						<div className="row justify-content-center">
							<img src={info} alt="CaptainMe.AI Process"/>
						</div>
					</div>
				</div> */}


		</React.Fragment>
	)
}

export default Home;
