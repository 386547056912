import React from 'react';
import logo from './images/logo/cai/captainmeai-croped.png'; 
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { menuLinks, sections } from './constants'
import Message from './components/Message'


function isServiceNavActive() {
  return String(window.location.href).includes('/services/')
}

function Menu() {
  return(
    <React.Fragment>
      <header className="theme-main-menu sticky-menu theme-menu-four">
        <div className="inner-content">
          <div className="d-flex align-items-center">
            <div className="logo-main order-lg-0">
              <Link to="/"  className="d-block">
                <img src={logo} alt="CaptainMe.AI"/>
              </Link>
            </div>

            <Message />

            <Navbar className="order-lg-2" expand="lg">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                  <ul className="navbar-nav">
                    {/**
                    =============================================
                      Logo in Menu
                    ============================================== 
                    */}
                    <li className="d-block d-lg-none">
                      <div className="logo">
                        <a href="index.html" className="d-block">
                          <img src={logo} alt="CaptainMe.AI"/>
                        </a>
                      </div>
                    </li>

                    <Nav.Item>
                      <LinkContainer activeClassName="active" to="/"> 
                        <Nav.Link>
                          Home
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>

                    <NavDropdown 
                      title="Services" 
                      id="collasible-nav-dropdown"
                      active={isServiceNavActive()}
                    >                        
                      <LinkContainer to={menuLinks.servicesDataEngineering}> 
                        <NavDropdown.Item>
                          {sections.services.dataEngineering.title}
                        </NavDropdown.Item>
                      </LinkContainer>
                      
                      <LinkContainer to={menuLinks.servicesMachineLearning}> 
                        <NavDropdown.Item>
                          {sections.services.machineLearning.title}
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to={menuLinks.servicesPredictiveAnalytics}> 
                        <NavDropdown.Item>
                          {sections.services.predictiveAnalytics.title}
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to={menuLinks.servicesComputerVision}> 
                        <NavDropdown.Item>
                          {sections.services.computerVision.title}
                        </NavDropdown.Item>
                      </LinkContainer>               
                      
                      <LinkContainer to={menuLinks.servicesNaturalLanguageProcessing}>
                        <NavDropdown.Item>
                          {sections.services.naturalLanguageProcessing.title}
                        </NavDropdown.Item>
                      </LinkContainer>  
                    </NavDropdown>
                    {/* <Nav.Item>
                      <LinkContainer activeClassName="active" to="/portfolio"> 
                        <Nav.Link>
                          Portfolio
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item> */}

                    <Nav.Item>
                      <LinkContainer activeClassName="active" to="/blog"> 
                        <Nav.Link>
                          Blog
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>

                    <Nav.Item>
                      <LinkContainer activeClassName="active" to="/about" > 
                        <Nav.Link>
                          About
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                  </ul>
                  
                  <Message type='mobile'/>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </header> 
    </React.Fragment>
  )
}


export default Menu;
