import React from 'react';
import ReactPlayer from 'react-player';
import { useEffect } from 'react';

// const squatAllDegress = 'squat-all-degree.MOV'
const squat0Degree = 'squat-0-degree.MOV'
const squat45Degree = 'squat-45-degree.MOV'
const squat90Degree = 'squat-90-degree.MOV'
const squat135Degree = 'squat-135-degree.MOV'
const squat180Degree = 'squat-180-degree.MOV'

const squats = [
  // {
  //   title: 'Squat - All Degree - with Moving Angle Video',
  //   video: squatAllDegress,
  //   link: '#squat-all-degree'
  // },
  {
    title: 'Squat - 0 Degree - with Shooting Angle Video',
    video: squat0Degree,
    link: '#squat-0-degree'
  },
  {
    title: 'Squat - 45 Degree - with Shooting Angle Video',
    video: squat45Degree,
    link: '#squat-45-degree'
  },
  {
    title: 'Squat - 90 Degree - with Shooting Angle Video',
    video: squat90Degree,
    link: '#squat-90-degree - with Shooting Angle Video'
  },
  {
    title: 'Squat - 135 Degree - with Shooting Angle Video',
    video: squat135Degree,
    link: '#squat-135-degree - with Shooting Angle Video'
  },
  {
    title: 'Squat - 180 Degree - with Shooting Angle Video',
    video: squat180Degree,
    link: '#squat-180-degree'
  },
]

function ProjectETExamples() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <React.Fragment>
      {/**
			=============================================
				Theme Inner Banner / Header
			============================================== 
      */}
      <div className="theme-inner-banner">
        <div className="container">
          <h2 className="intro-title text-center">Exercise Detection Examples</h2>
        </div>
      </div>

      {/* <!-- 
			=============================================
				Service Details
			============================================== 
			--> */}
      <div className="service-details position-relative mt-30 mb-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 order-lg-1">

              {squats.map((val, index) => {
                const key = `squad-${index}`
                return (


                  <div className='row' key={key}>
                    <div className="service-details-meta ms-xxl-4">
                      <a href={val.link}>
                        <h2 className="sub-title">{val.title}</h2>
                      </a>
                      {/* <p>Exercise Detection Examples</p> */}
                      <div className="row">
                        <ReactPlayer
                          url={`https://captainme-ai.s3.us-west-2.amazonaws.com/project-ed-videos-zHidbjuueU5Xb/${val.video}`}
                          width="40%"
                          height="40%"
                          controls={true}
                          muted={true}
                          pip={false}
                          config={{
                            file: {
                              attributes: {
                                onContextMenu: e => e.preventDefault(),
                                controlsList: 'nodownload nofullscreen noremoteplayback',
                                disablePictureInPicture: true
                              }
                            }
                          }}
                        />
                        <hr />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ProjectETExamples;
