import React from 'react';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

import ServicesSubMenu from './ServicesSubMenu';
import Technologies from '../components/Technologies';
import { Link } from "react-router-dom";
import image_13 from './../images/media/img_13.jpg'
import { techDescription } from './../constants'

function ServiceContent(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const {
    pageTitle,
    title,
    description,
    img,
    useCases,
    bestPractices,
    breadCrumpMenu,
    currentMenuPage
  } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description}></meta>
        <meta property="og:url" content={window.location}></meta>
        <meta property="og:title" content={"CaptainMe.AI - " + pageTitle}></meta>
        <meta property="og:description" content={description}></meta>
      </Helmet>

      {/**
			=============================================
				Theme Inner Banner / Header
			============================================== 
      */}
      <div className="theme-inner-banner">
        <div className="container">
          <h2 className="intro-title text-center">{pageTitle}</h2>
          <ul className="page-breadcrumb style-none d-flex justify-content-center">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Services</li>
            <li className="current-page">{breadCrumpMenu}</li>
          </ul>
        </div>
      </div>

      {/* <!-- 
			=============================================
				Service Details
			============================================== 
			--> */}
      <div className="service-details position-relative mt-30 mb-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 order-lg-1">
              <div className="service-details-meta ms-xxl-4">
                <h2 className="sub-title">{title}</h2>
                <p>{description}</p>

                {/* <img src={img || image_13} alt="" className="main-img-meta" /> */}

                <div className="mt-75 lg-mt-50">
                  <div className="row gx-xxl-5">

                    {/* <!-- 
                        =============================================
                          Use Cases
                        ============================================== 
                    --> */}
                    <div className="col-lg-6">
                      <h3 className="sub-title">Use Cases</h3>
                      <ul className="style-none list-item md-mb-40">
                        {useCases.map((val, index) => {
                          const key = `use-cases-item-${index}`
                          return (
                            <li key={key}>{val}</li>
                          )
                        })}
                      </ul>
                    </div>
                    {/* <!-- 
                        =============================================
                          Best Practices
                        ============================================== 
                    --> */}
                    <div className="col-lg-6">
                      <h3 className="sub-title">Best Practices</h3>
                      <ul className="style-none list-item md-mb-40">
                        {bestPractices.map((val, index) => {
                          const key = `best-practices-item-${index}`
                          return (
                            <li key={key}>{val}</li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <h3 className="sub-title mt-20" >Technology we use</h3>
                <p>{techDescription}</p>
                <Technologies />
              </div>
            </div>
            <ServicesSubMenu currentPage={currentMenuPage} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ServiceContent;
