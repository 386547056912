import React from 'react';
import Menu from '../Menu';
import { menuLinks, sections, bestPracticesDefault } from './../constants'
import ils_15 from './../images/assets/ils_15.svg';
import ServiceContent from './ServiceContent';

const title = sections.services.predictiveAnalytics.page.title
const description = 'Time Series data is essential for understanding the current state of any business. We know that and specialize in providing real-time seasonality predictive analytics with state-of-the-art Machine Learning models.'

const useCases = [
	'Sales / Demand forecasting with Seasonality',
	'Inventory forecasting',
	'Seasonality Detection',
	'Anomaly & Patterns Detection',
	'Gather Business Intelligence from Existing IPs'
]
const bestPractices = bestPracticesDefault.concat([
	'Online Learning for real-time predictions',
])

function ServicePredictiveAnalytics() {
	return (
		<React.Fragment>
			< Menu />

			<ServiceContent
				pageTitle='Predictive Analytics / Timeseries'
				title={title}
				description={description}
				useCases={useCases}
				bestPractices={bestPractices}
				img={ils_15}
				breadCrumpMenu={sections.services.predictiveAnalytics.title}
				currentMenuPage={menuLinks.servicesPredictiveAnalytics}
			/>

		</React.Fragment>
	)
}

export default ServicePredictiveAnalytics;
