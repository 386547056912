import React from 'react';
import Modal from 'react-modal';
// import { Link } from "react-router-dom";
// import { sections } from './constants'
import { useState } from "react"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    zIndex: 1000,
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 1000 },
};

Modal.setAppElement('#root');

function Message(props) {

  /**
   * Form Submission
   */

  // https://beta.reactjs.org/reference/react-dom/components/select#reading-the-select-box-value-when-submitting-a-form
  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data

   //const submitButton = document.getElementById('submit-contact-us');

    // 1. Get Form Data
    const form = e.target;
    const formData = new FormData(form);
    // You can generate a URL out of it, as the browser does by default:
    // You can work with it as a plain object.
    const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson); // (!) This doesn't include multiple select values

    // 2. Validate
    const email = formJson['email'].trim();
    const name = formJson['name'].trim();
    // const phone = formJson['phone'].trim();
    const message = formJson['message'].trim();

    if (!email || !name || !message) {
      alert('Please fill out all fields');
      return;
    }

    // validate email
    if (!email.includes('@')) {
      alert('Please enter a valid email');
      return;
    }

    // validate email with regex
    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert('Please enter a valid email');
      return;
    }

    // 3. Submit Form
    const apiPayload = {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'Gg1aB0YX1652IJUdFdR7maiKBawjLKTT6ocvKbyd',
      },
      body: JSON.stringify(formJson)
    };

    fetch('https://rb2sf4ng63.execute-api.us-west-2.amazonaws.com/live/v1/contact_us', apiPayload).then((response) => {
      console.log(response)
      if (response.status === 200) {
        setIsSubmitted(true);
      }
    })
  }

  /**
   * END Form Submission
   */
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formIsSubmitted, setIsSubmitted] = React.useState(false);

  function openModal(ev) {
    setIsOpen(true);
    ev.preventDefault();
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsSubmitted(false);
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      {props.type && props.type === 'mobile'  ? (
        <div className="mobile-content d-block d-lg-none">
          <div className="d-flex flex-column align-items-center justify-content-center mt-70">
            <a href="/" onClick={openModal} className="send-msg-btn tran3s mb-10">Send us Message</a>
          </div>
        </div>
      ) : (
      <div className="right-widget d-flex align-items-center ms-auto order-lg-3">
        <a href="/" onClick={openModal} className="send-msg-btn tran3s d-none d-lg-block">
          Send us Message
        </a>
      </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={true}
      >
        {/* <button onClick={closeModal}>close</button> */}
          <div className="mt-20 lg-mt-70">
            <div className="container">
              <div className="row gx-xxl-5">
                    {formIsSubmitted ? (
                      <div className="text-center">
                          <p className="text-center" >Thank you for reaching out.<br/>We will get back to you soon.</p>
                          
                          <div class="title-style-one">
                            <div class="sc-title-three">CaptainMe.AI Team</div>
                          </div>

                          <button className="btn btn-primary" onClick={closeModal}>Close</button>
                      </div>
                    ) : ( 
                      <div className="d-flex order-lg-last">
                      <div className="form-style-one">
                        <h3 className="form-title pb-40 lg-pb-20">Get in touch.</h3>
                        <form action="/" id="contact-form"  data-toggle="validator"  method="post" onSubmit={handleSubmit}>
                          <div className="messages"></div>
                          <div className="row controls">
                            <div className="col-12">
                              <div className="input-group-meta form-group mb-30">
                                <label>Name*</label>
                                <input type="text" placeholder="Your name" name="name" required="required" data-error="Name is required."/>
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>
                            
                            <div className="col-12">
                              <div className="input-group-meta form-group mb-30">
                                <label>Email*</label>
                                <input type="email" placeholder="Your email" name="email" required="required" data-error="Valid email is required."/>
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="input-group-meta form-group mb-30">
                                <textarea placeholder="How can we help?" name="message" required="required" data-error="Please,leave us a message."></textarea>
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>
                            <div className="col-12"><button id="submit-contact-us" className="btn-eight ripple-btn">Send</button></div>
                          </div>
                        </form>
                      </div>
                      </div>
                    )}
              </div>
            </div>
          </div>
      </Modal>
    </React.Fragment>
  );
}
export default Message;
