import React from 'react';
import Menu from './Menu';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import vl6 from './images/about/velimir-lesikov-machine-learning.png';
import shape_38 from './images/shape/shape_38.svg';
import shape_39 from './images/shape/shape_39.svg';

// credentials
import ml_cred from './images/credentials/aws-certified-machine-learning-specialty.png';
import a_cred from './images/credentials/aws-certified-solutions-architect-professional.png';

function About() {

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<React.Fragment>
			<Helmet>
				<title>About / Leadership</title>
				<meta name="description" content='Vel Lesikov - Principal Software & Machine Learning Engineer / Founder'></meta>
				<meta property="og:url" content={window.location}></meta>
				<meta property="og:title" content='CaptainMe.AI - About / Leadership'></meta>
			</Helmet>

			< Menu />

			{/**
			=============================================
				Theme Inner Banner / Header
			============================================== 
      */}
			<div className="theme-inner-banner">
				<div className="container">
					<h2 className="intro-title text-center">Leadership</h2>
					<ul className="page-breadcrumb style-none d-flex justify-content-center">
						<li>
							<Link to="/">Home</Link>
						</li>
						<li className="current-page">About</li>
					</ul>
				</div>
				<img src={shape_38} alt="" className="shapes shape-one" />
				<img src={shape_39} alt="" className="shapes shape-two" />
			</div>

			{/**
			=====================================================
				About Detail
			=====================================================
      */}
			<div className="team-details mt-20 mb-160 lg-mt-10 lg-mb-10">
				<div className="container">
					<div className="row">
						<div className="col-xxl-10 col-lg-12 col-md-10 m-auto">
							<div className="d-lg-flex align-items-center">

								<div className="bio-title-wrapper">
									<div className="name">Vel Lesikov</div>
									<div className="position"> Principal Software & Machine Learning Engineer / Founder</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xxl-10 col-lg-12 col-md-10 m-auto">
							<div className="main-bg d-lg-flex">

								<div className="img-meta pt-40">
									<img src={vl6} alt="Velimir Lesikov about - CaptainMe.AI - Principal Software & Machine Learning Engineer" className="w-100" />
								</div>

								<div className="text-wrapper align-items-center">
									<h6>BIO & EXPERIENCE</h6>
									<p className="">Velimir (Vel) Lesikov is the founder and Principal Software & Machine Learning Engineer at CapatainMe AI. His passion is working on data-driven solutions, building intelligent software systems from the ground up, and improving and scaling existing ones.
									</p>
									<p className="pb-10">
										Velimir has a proven history of building large, scalable online systems from scratch, touching all levels of the technical stack, from a solo engineer to leading large teams on multimillion-dollar projects.
									</p>
									<p className="pb-10">
										In the past, he managed and led the technical efforts of a SaaS Advertisement product from inception to 10x company growth and acquisition in less than five years.
									</p>
									<p className="pb-10">
										As the technical leader at CaptainMe AI, he has helped e-commerce and fit tech companies develop new solutions, understand it's data potentials and scale.
									</p>
									<p className="pb-10">
										Velimir is always looking for innovative ways to apply AI to real-world problems. His expertise includes AI problem-solving and ML solution design, where he applies simple heuristics to answer complex marketing and business problems.
									</p>
									<p className="pb-10">
										As a Machine Learning Engineer, he has architected various ML, NLP/LLM, and Computer Vision solutions. He has hands-on experience in ETL, building data pipelines, data sanitation, and augmentation techniques. His background as a Privacy Engineer ensures that an AI product is privacy-aware and user-centric.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="main-bg m-auto d-lg-flex">
							<div className="text-wrapper creds">
								<h6>Credentials</h6>
								<ul>
									<li>Bachelor's degree in Computer Science from CSUN</li>
									<li>
										<a href="https://digitalcredential.stanford.edu/check/AC7A1106CF6A45F041E7F2B0A7A93044B0CBADCDFFBD8F46A4A84FC01E2CC74DYUxYTWVld2V0VFAzU2Z6TjdvbktpdWtGNGhoeDJzUFkwVEt1L2tSRGJscWdYSnpU" rel="noreferrer" target="_blank">
											Artificial Intelligence Professional Certificate from Stanford University
										</a>
									</li>
									<li>
										<a href="https://www.credly.com/badges/0db46f4c-3f60-413d-aa31-8d5e56982d2d/public_url" rel="noreferrer" target="_blank">
											AWS Certified Solutions Architect – Professional
										</a>
									</li>
									<li>
										<a href="https://www.credly.com/badges/bf20eeea-2ba4-4a54-9e2b-eb9127d4f748/public_url" rel="noreferrer" target="_blank">
											AWS Certified Machine Learning – Specialty
										</a>
									</li>
								</ul>
							</div>

							<div className="column col-md-10 d-flex creds-icons">
								<div className="d-flex">
									<a href="https://www.credly.com/badges/bf20eeea-2ba4-4a54-9e2b-eb9127d4f748/public_url" rel="noreferrer" target="_blank">
										<img src={ml_cred} alt="" className="m-auto" />
									</a>
								</div>
								<div className="d-flex">
									<a href="https://www.credly.com/badges/0db46f4c-3f60-413d-aa31-8d5e56982d2d/public_url" rel="noreferrer" target="_blank">
										<img src={a_cred} alt="" className="m-auto" />
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-xxl-10 col-lg-12 col-md-10 m-auto">
							<div className="main-bg d-lg-flex align-items-center">
								<div className="text-wrapper">
									<h6>FOLLOW & CONTACT</h6>
									<ul className="social-icon style-none d-flex pt-15">
										<li><a href="https://www.linkedin.com/company/captainme-ai" target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a></li>
										<li><a href="https://github.com/velles" target="_blank" rel="noreferrer"><i className="fab fa-github"></i></a></li>
										<li><a href="https://medium.com/@vel-lesikov" target="_blank" rel="noreferrer"><i className="fab fa-medium"></i></a></li>
										<li><a href="mailto:vel@captainme.com" target="_blank" rel="noreferrer"><i className="far fa-envelope"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default About;
