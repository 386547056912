import React from 'react';

import Menu from '../Menu';
import { menuLinks, sections, bestPracticesDefault } from './../constants'
import ils_19 from './../images/assets/ils_19.svg';

import ServiceContent from './ServiceContent';

const title = sections.services.dataEngineering.page.title
const description = 'Data Engineering & ML Ops services help collect, transform, and publish data. We can train and deploy existing Machine Learning Models or build a new one as part of other service offerings.'
const useCases = [
	'Gather Business Intelligence from Existing IPs',
	'Automate Data Collection and Transformation',
	'Optimize the speed and reduce cost on existing data lakes',
	'Deliver additional business KPIs via new data aggregation',
	'Automate the process of training and deploying Machine Learning Models',
	'Improve Reliability of Existing Data Lakes'
]
const bestPractices = bestPracticesDefault

function ServiceDataEngineering() {
	return (
		<React.Fragment>
			< Menu />

			<ServiceContent
				pageTitle='Data Engineering & ML Ops'
				title={title}
				description={description}
				img={ils_19}
				useCases={useCases}
				bestPractices={bestPractices}
				breadCrumpMenu={sections.services.dataEngineering.title}
				currentMenuPage={menuLinks.servicesDataEngineering}
			/>

		</React.Fragment>
	)
}

export default ServiceDataEngineering;
