import React from 'react';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import './css/style.css';
import './css/responsive.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot, hydrateRoot } from 'react-dom/client';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
  root.render(<App/>);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
