import React from 'react';
import ReactPlayer from 'react-player';
import { useEffect } from 'react';

import overheadpress0DegreeImage from './../ed-videos/overheadpress-0-degree.jpg'
import overheadpress45DegreeImage from './../ed-videos/overheadpress-45-degree.jpg'
import overheadpress90DegreeImage from './../ed-videos/overheadpress-90-degree.jpg'
import overheadpress135DegreeImage from './../ed-videos/overheadpress-135-degree.jpg'
import overheadpress180DegreeImage from './../ed-videos/overheadpress-180-degree.jpg'

const pushupAllDegress = 'pushup-all-degree.MOV'
const situpAllDegress = 'situp-all-degree.MOV'
const bicepsCurlAllDegress = 'biceps-curl-all-degree.MOV'
const bicepsCurl135Degree = 'biceps-curl-135-degree.MOV'
const bulgarianSquatAllDegress = 'bulgariansquat-all-degree.MOV'
const lateralRaiseAllDegress = 'lateralraise-all-degree.MOV'
const overheadpressAllDegress = 'overheadpress-all-degree.MOV'
const lungeAllDegress = 'lunge-all-degree.MOV'
const deadLiftAllDegress = 'deadlift-all-degree.MOV'
const plankAllDegress = 'plank-all-degree.MOV'

const imageExamples = [
  {
    title: '0 Degree',
    image: overheadpress0DegreeImage,
    link: '#overhead-press-0-degree'
  },
  {
    title: '45 Degree',
    image: overheadpress45DegreeImage,
    link: '#overhead-press-45-degree'
  },
  {
    title: '90 Degree',
    image: overheadpress90DegreeImage,
    link: '#overhead-press-90-degree'
  },
  {
    title: '135 Degree',
    image: overheadpress135DegreeImage,
    link: '#overhead-press-135-degree'
  },
  {
    title: '180 Degree',
    image: overheadpress180DegreeImage,
    link: '#overhead-press-180-degree'
  },
]


const others = [
  // {
  //   title: 'Push Up - All Degree - EXAMPLE ONLY - 5 DIFFERENT VIDEOS ARE RQUIRED',
  //   video: pushupAllDegress,
  //   link: '#pushup-all-degree'
  // },
  // {
  //   title: 'Sit Up - All Degree - ',
  //   video: situpAllDegress,
  //   link: '#situp-all-degree'
  // },
  {
    title: 'Biceps Curl - All Degree - EXAMPLE ONLY - 5 DIFFERENT VIDEOS ARE RQUIRED',
    video: bicepsCurlAllDegress,
    link: '#biceps-curl-all-degree'
  },
  {
    title: 'Biceps Curl - 135 Degree - with Shooting Angle Video',
    video: bicepsCurl135Degree,
    link: '#biceps-curl-135-degree'
  },
  // {
  //   title: 'Bulgarian Squat - All Degree - with Moving Angle Video',
  //   video: bulgarianSquatAllDegress,
  //   link: '#bulgarian-squat-all-degree'
  // },
  {
    title: 'Lateral Raise - All Degree - EXAMPLE ONLY - 5 DIFFERENT VIDEOS ARE RQUIRED',
    video: lateralRaiseAllDegress,
    link: '#lateral-raise-all-degree'
  },
  {
    title: 'Overhead Press - All Degree - EXAMPLE ONLY - 5 DIFFERENT VIDEOS ARE RQUIRED',
    video: overheadpressAllDegress,
    link: '#overhead-press-all-degree'
  },
  {
    title: 'Lunge - AllDegree - EXAMPLE ONLY - 5 DIFFERENT VIDEOS ARE RQUIRED',
    video: lungeAllDegress,
    link: '#lunge-all-degree'
  },
  // {
  //   title: 'Dead Lift - All Degree - with Moving Angle Video',
  //   video: deadLiftAllDegress,
  //   link: '#dead-lift-all-degree'
  // },
  {
    title: 'Plank - All Degree - with Moving Angle Video',
    video: plankAllDegress,
    link: '#plank-all-degree'
  }
]

function ProjectETExamplesOthers() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <React.Fragment>
      {/**
			=============================================
				Theme Inner Banner / Header
			============================================== 
      */}
      <div className="theme-inner-banner">
        <div className="container">
          <h2 className="intro-title text-center">Exercise Detection Examples</h2>
        </div>
      </div>

      {/* <!-- 
			=============================================
				Service Details
			============================================== 
			--> */}
      <div className="service-details position-relative mt-30 mb-40">
        <div className="container">

          {/* <!--
			=====================================================
				Videos
			=====================================================
			--> */}

          <div className="row">
            <div className="col-xl-8 col-lg-8 order-lg-1">

              {others.map((val, index) => {
                const key = `squad-${index}`
                return (


                  <div className='row' key={key}>
                    <div className="service-details-meta ms-xxl-4">
                      <a href={val.link}>
                        <h2 className="sub-title">{val.title}</h2>
                      </a>
                      {/* <p>Exercise Detection Examples</p> */}
                      <div className="row">
                        <ReactPlayer
                          url={`https://captainme-ai.s3.us-west-2.amazonaws.com/project-ed-videos-zHidbjuueU5Xb/${val.video}`}
                          width="40%"
                          height="40%"
                          controls={true}
                          muted={true}
                          config={{
                            file: {
                              attributes: {
                                onContextMenu: e => e.preventDefault(),
                                controlsList: 'nodownload nofullscreen noremoteplayback',
                                disablePictureInPicture: true
                              }
                            }
                          }}
                        />
                        <hr />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>


          {/* <!--
			=====================================================
				IMAGES
			=====================================================
			--> */}
          <div className="row">
            <h3 className='sub-title' id='angle-examples'>Overhead Press - Angle Examples</h3>
            {imageExamples.map((val, index) => {
              const key = `squad-image-${index}`
              return (
                <div className="col-lg-4 col-sm-6 d-flex" key={key}>
                  <article className=" mt-45">
                    <figure className="post-img m0">
                      <a href={val.link} >
                        <img src={val.image} alt="" className="w-100" />
                      </a>
                    </figure>
                    <div className="post-data">
                      <h5>{val.title}</h5>
                    </div>
                  </article>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ProjectETExamplesOthers;
