import React from 'react';
import { Link } from "react-router-dom";
import { sections } from './constants'
import logo from './images/logo/cai/captainmeai-symbol.png';

function Footer() {
	return (
		<div className="footer-style-four theme-basic-footer">
			<div className="container">
				<div className="inner-wrapper">
					<div className="row">
						<div className="col-lg-4 footer-intro mb-40">
							<p>Leverage your data, <br />
								elevate your business, <br />
								be the captain of your firm.
							</p>
							<ul className="d-flex social-icon style-none">
								{/* <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
									<li><a href="#"><i className="fab fa-twitter"></i></a></li> */}
								<li>
									<a
										href="https://www.linkedin.com/company/captainme-ai/about/"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fab fa-linkedin-in"></i>
									</a>
								</li>
								<li>
									<a
										href="https://github.com/CaptainMe-AI"
										target="_blank"
										rel="noreferrer"
									>
										<i className="fab fa-github"></i>
									</a>
								</li>
							</ul>
						</div>
						<div className="col-lg-2 col-sm-4 ms-auto mb-30">
							<h5 className="footer-title">Links</h5>
							<ul className="footer-nav-link style-none">
								<li>
									<Link to={sections.home.path}>{sections.home.title}</Link>
								</li>
								{/* <li>
										<Link to={sections.portfolio.path}>{sections.portfolio.title}</Link>
									</li> */}
								<li>
									<Link to={sections.blog.path}>{sections.blog.title}</Link>
								</li>
								<li>
									<Link to={sections.about.path}>{sections.about.title}</Link>
								</li>
							</ul>
						</div>
						<div className="col-lg-3 col-sm-4 mb-30">
							<h5 className="footer-title">Services</h5>
							<ul className="footer-nav-link style-none">
								{Object.keys(sections.services).map((key) => {
									return <li key={sections.services[key].path}><Link to={sections.services[key].path}>{sections.services[key].title}</Link></li>
								})}
							</ul>
						</div>
					</div>

					<div className="bottom-footer">
						<div className="d-lg-flex justify-content-between align-items-center">
							<p className="copyright text-center order-lg-0 pb-15">Copyright @2023 CaptainMe.AI</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer;
