import React from 'react';
import Carousel from 'react-grid-carousel'
import { tech } from './../constants'

function Technologies(props) {

  const ratio = Number(props.ratio) || 0
  return (
    <React.Fragment>
      <Carousel
        cols={4 + ratio}
        rows={2}
        gap={10}
        loop={true}
        showDots={true}
        responsiveLayout={[
          {
            autoplay: 1200,
            breakpoint: 1400,
            cols: 4 + ratio
          },
          {
            autoplay: 1600,
            breakpoint: 1200,
            cols: 4 + ratio
          },
          {
            autoplay: 1800,
            breakpoint: 990,
            cols: 4
          },
          {
            autoplay: 2000,
            breakpoint: 767,
            cols: 2
          },
          {
            autoplay: 2000,
            breakpoint: 500,
            cols: 2
          }
        ]}
        mobileBreakpoint={200}
        // containerStyle={{ background: 'linear-gradient(0deg, rgba(248, 253, 255, 0) 0%, rgba(241, 252, 255, 0.51782) 48.22%, #F6FDFF 100%)' }}
        // arrowRight={<ArrowBtn type="right" />}
        // arrowLeft={<ArrowBtn type="left" />}
      >

          {tech.map((content, index) => {
              const key = `tech-frameworks-${index}`
            return (
              <Carousel.Item key={key}>
                  <div className="block-style-technologies color-two ps-2 pe-2 text-center tran3s mt-10">
                    <div className="icon d-flex align-items-end">
                      <img src={content[2]} alt="" className="m-auto"/>
                    </div>
                    <p><a href={content[1]} target='_blank' rel='noreferrer'>{content[0]}</a></p>
                  </div>
              
              </Carousel.Item>
            )
          })}
      </Carousel>



      {/* <div>
        <div className="row">
          {tech.map((content, index) => {
              const key = `tech-frameworks-${index}`
            return (
              <div className="col-md-4 d-flex block-style-technologies-container" data-aos="fade-up" key={key}>
                <div className="block-style-technologies color-two ps-2 pe-2 text-center tran3s mt-10">
                  <div className="icon d-flex align-items-end">
                    <img src={content[2]} alt="" className="m-auto"/>
                  </div>
                  <p><a href={content[1]} target='_blank' rel='noreferrer'>{content[0]}</a></p>
                </div>
              </div>
            )
          })}
        </div>
      </div> */}
    </React.Fragment>
  );
}
export default Technologies;
