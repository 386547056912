import React from 'react';

import Menu from '../Menu';
import { menuLinks, sections, bestPracticesDefault } from './../constants'
import ils_19 from './../images/assets/ils_19.svg';

import ServiceContent from './ServiceContent';

const title = sections.services.machineLearning.page.title
const description = 'We can apply foundational Machine Learning approaches to day-to-day and long-term operations to increase productivity and business growth.'
const useCases = [
	'Customers / Behavior Segmentation',
	'Churn Prevention',
	'Cohort Analysis',
	'Customer Lifetime Value',
	'Content Recommendation',
	'Anomaly detection'
]
const bestPractices = bestPracticesDefault

function ServicesMachineLearning() {
	return (
		<React.Fragment>
			< Menu />

			<ServiceContent
				pageTitle='Machine Learning'
				title={title}
				description={description}
				img={ils_19}
				useCases={useCases}
				bestPractices={bestPractices}
				breadCrumpMenu={sections.services.machineLearning.title}
				currentMenuPage={menuLinks.servicesMachineLearning}
			/>

		</React.Fragment>
	)
}

export default ServicesMachineLearning;
